import type { IDadataAddress, DadataAddressDadataValue } from "../interfaces/dadata"

export function getAddress(address: DadataAddressDadataValue) {
  const {
    unrestricted_value,
    data: { flat },
  } = address

  if (flat && flat.trim() !== "") {
    return `${unrestricted_value}, кв ${flat}`
  }

  return unrestricted_value
}

export function getAddressForDelivery(addressStruct: IDadataAddress, address: string) {
  const { city_kladr_id, settlement_kladr_id } = addressStruct.dadataValue?.data || {}

  return {
    kladrID: settlement_kladr_id || city_kladr_id,
    address,
  }
}
