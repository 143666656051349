import formatToRequest from "@ecom/ui/helpers/formatToRequest"
import { formatDate } from "@ecom/ui/utils/formatters"

import { getAddress } from "../../helpers/getAddress"

import type { EmploymentFormData, EmploymentFormDataFormatted } from "./types"

const fieldsType = {
  workDate: "date",
  workAddress: "dadataAddress",
}

export function formatValuesToRequest(values: EmploymentFormData): EmploymentFormDataFormatted {
  const valuesToRequest = formatToRequest(values, fieldsType)

  if (valuesToRequest.workAddress) {
    valuesToRequest.workAddress = getAddress(valuesToRequest.workAddress)
  }

  if (valuesToRequest.otherSalary) {
    valuesToRequest.otherSalary = valuesToRequest.otherSalary.replace(/ /g, "")
  }

  valuesToRequest.salary = valuesToRequest.salary.replace(/ /g, "")

  return valuesToRequest
}

export const WORK_SECTORS = [
  "Армия и флот",
  "Строительство",
  "Консалтинг",
  "Оптовая или розничная торговля",
  "Охранная деятельность",
  "Промышленность и машиностроение",
  "Топливно-энергетический комплекс",
  "Финансы, банки, страхование",
  "Органы власти и управления",
  "Информационные технологии и телекоммуникации",
  "Медицина",
  "Образование",
  "Наука",
  "Социальная сфера",
  "Туризм",
  "Транспорт, логистика",
  "Прочие услуги",
  "Другая отрасль",
].map((v, i) => ({ value: i, label: v }))

export const SOCIAL_STATUS_LIST = [
  "Индивидуальный предприниматель",
  "Рабочий, служащий по найму",
  "Владелец предприятия",
  "Неработающий пенсионер",
  "Работающий пенсионер",
  "Студент",
  "Иное",
].map((v, i) => ({ value: i, label: v }))

export const workPhoneValidator = (phone: string) => {
  if (phone.length !== 11) {
    return "Введите номер телефона полностью"
  }
  const re = /^(8|7)/
  if (!re.test(phone)) {
    return "Номер должен начинатся с 8 или с +7"
  }

  return null
}

export function passportDateValidator(passportIssuingDate: string, birthDate?: string) {
  // eslint-disable-next-line react/destructuring-assignment
  if (!passportIssuingDate || passportIssuingDate.length < 10) {
    return "Заполните поле"
  }

  const passportDate = new Date(formatDate(passportIssuingDate))
  const birthdayDate = birthDate ? new Date(formatDate(birthDate)) : passportDate
  const fourTeenYearsOld = birthdayDate.setFullYear(birthdayDate.getFullYear())

  if (passportDate.getTime() - fourTeenYearsOld < 0) {
    return "Дата начала работы должна быть больше даты рождения"
  }

  return null
}
