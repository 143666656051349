import React, { SVGAttributes } from "react"

type ArrowProps = {
  color?: string
} & SVGAttributes<SVGElement>

export const GuardSecuritySvg: React.FC<ArrowProps> = ({ color = "#fff", ...props }) => (
  <svg viewBox="0 0 24 30" {...props}>
    <path
      d="M24 13.6667C24 21.0667 18.88 27.9867 12 29.6667C5.12 27.9867 0 21.0667 0 13.6667V5.66671L12 0.333374L24 5.66671V13.6667ZM12 27C17 25.6667 21.3333 19.72 21.3333 13.96V7.40004L12 3.24004L2.66667 7.40004V13.96C2.66667 19.72 7 25.6667 12 27ZM15.7333 13.6667V11.6667C15.7333 9.80004 13.8667 8.33337 12 8.33337C10.1333 8.33337 8.26667 9.80004 8.26667 11.6667V13.6667C7.46667 13.6667 6.66667 14.4667 6.66667 15.2667V19.9334C6.66667 20.8667 7.46667 21.6667 8.26667 21.6667H15.6C16.5333 21.6667 17.3333 20.8667 17.3333 20.0667V15.4C17.3333 14.4667 16.5333 13.6667 15.7333 13.6667ZM14 13.6667H10V11.6667C10 10.6 10.9333 9.93337 12 9.93337C13.0667 9.93337 14 10.6 14 11.6667V13.6667Z"
      fill={color}
    />
  </svg>
)
